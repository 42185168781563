<template>
  <a-spin :spinning="spinning" style="height:100%;background-color: rgb(217, 220, 224);">
    <div style="height:100%;overflow:hidden">
      <a-row class="fun" style="overflow:hidden">
        <a-col class="fun-left" :span="screenWidth > 700 ? 18 : 24" :style="screenWidth > 900 ? '' : 'padding-left:0'">
          <div class="logo" v-if="screenWidth > 900">
            <img :src="logo" alt="logo" @click="goToHome" />
            <span>图像对比</span>
          </div>
          <a-icon
            type="left-circle"
            style="margin-right:15px;font-size:14px;"
            :class="pageNum <= 1 ? 'icon-color' : ''"
            @click="
              () => {
                if (this.imgLefts.length > 0) {
                  this.pageNum > 1 && --this.pageNum
                } else {
                  this.pageNum > 1 && --this.pageNum
                }
              }
            "
          />
          <a-icon
            type="right-circle"
            style="margin-right:15px;font-size:14px;"
            :class="pageNum == imgLefts.length || pageNum == imgRights.length ? 'icon-color' : ''"
            @click="
              () => {
                if (this.imgLefts.length > 0) {
                  this.pageNum < this.imgLefts.length && ++this.pageNum
                } else {
                  this.pageNum < this.imgRights.length && ++this.pageNum
                }
              }
            "
          />
          <input type="text" v-model="pageNum" @input="pageChange" class="fun-input" />
          <span>/{{ this.imgLefts.length || this.imgRights.length }}</span>
          <select @change="handleSelect" v-model="size" class="select_scale___2lG6l">
            <option value="auto">自动</option>
            <option value="0.1">10%</option>
            <option value="0.2">20%</option>
            <option value="0.3">30%</option>
            <option value="0.4">40%</option>
            <option value="0.5">50%</option>
            <option value="0.6">60%</option>
            <option value="0.7">70%</option>
            <option value="0.8">80%</option>
            <option value="0.9">90%</option>
            <option value="1">100%</option>
            <option value="2">200%</option>
            <option value="3">300%</option>
          </select>
          <a-slider :min="10" id="test" @change="sliderChange" v-model="sliderValue" :disabled="false" />
          <span style="margin-left:15px">
            对比容差：
          </span>
          <input type="text" v-model="formData.pixelToleranceLevel" class="fun-input" />
          <span style="margin-left:15px">
            标记区域：
          </span>
          <input type="text" v-model="formData.threshold" class="fun-input" />
        </a-col>
        <a-col class="fun-right" v-if="screenWidth > 700" :span="6" style="">
          <div style="display: flex;justify-content:space-between;width: 100%;">
            <div>
              <button @click="diff" class="btn" style="font-size:13px;margin:auto 0;min-width:48px;">对比</button>
              <button
                class="btn"
                style="font-size:13px;margin:auto 0;margin-left:0.8vw;min-width:48px;"
                @click="cencel"
              >
                清除
              </button>
            </div>
            <button
              class="btn"
              style="font-size:13px;margin:auto 0;margin-left:0.8vw;min-width:48px;"
              @click="handleLogout"
            >
              退出
            </button>
          </div>
        </a-col>
      </a-row>
      <transition name="fade">
        <a-row style="height:100%">
          <a-col :span="openSpan[0]">
            <div class="show-div">
              <div class="show-head">
                <div class="name">
                  <span
                    >【参考图片】{{
                      fileLeftList.length > 0
                        ? `${fileLeftList[pageNum - 1].name}${
                            imgSizeLeftList[fileLeftList[pageNum - 1].name]
                              ? '（' +
                                imgSizeLeftList[fileLeftList[pageNum - 1].name].imgWidth +
                                'x' +
                                imgSizeLeftList[fileLeftList[pageNum - 1].name].imgHeight +
                                '像素，' +
                                imgSizeLeftList[fileLeftList[pageNum - 1].name].size +
                                '）'
                              : ''
                          }`
                        : ''
                    }}</span
                  >
                  <a-icon type="close" class="close-icon" @click="cencelOne('left')" />
                </div>
              </div>
              <div class="show-body" :style="'background-color:' + this.backGroundColor">
                <div class="show-img" @mouseover="imgLeftScroll" @contextmenu.prevent="rightClick">
                  <a-upload-dragger
                    accept="image/*"
                    v-if="imgLefts.length == 0"
                    name="file"
                    :showUploadList="false"
                    :multiple="true"
                    :before-upload="beforeLeftUpload"
                    @change="handleLeftChange"
                  >
                    <div>
                      <p class="ant-upload-drag-icon">
                        <a-icon type="folder-open" />
                      </p>
                      <p class="ant-upload-text">
                        将文件拖到此处，或点击该区域任意处打开
                      </p>
                      <p class="ant-upload-hint">
                        支持多张图片或单张图片上传
                      </p>
                    </div>
                  </a-upload-dragger>
                  <div v-else class="img-list">
                    <img v-for="item in imgLefts" :key="item" :src="item" />
                  </div>
                  <div
                    v-if="isOpen"
                    :style="'opacity:' + openStyle"
                    @mouseover="
                      () => {
                        this.openStyle = 0.9
                      }
                    "
                    @mouseleave="
                      () => {
                        this.openStyle = 0
                      }
                    "
                    @click="open"
                    class="open"
                  >
                    <div>></div>
                  </div>
                  <div
                    v-if="!isOpen"
                    :style="'opacity:' + openStyle"
                    @mouseover="
                      () => {
                        this.openStyle = 0.9
                      }
                    "
                    @mouseleave="
                      () => {
                        this.openStyle = 0
                      }
                    "
                    @click="close"
                    class="close"
                  >
                    <div v-text="'<'"></div>
                  </div>
                </div>
              </div>
            </div>
          </a-col>
          <a-col :span="openSpan[1]">
            <div class="show-div">
              <div class="show-head">
                <div class="name">
                  【对比图片】
                  {{
                    fileRightList.length > 0
                      ? `${fileRightList[pageNum - 1].name}${
                          imgSizeRightList[fileRightList[pageNum - 1].name]
                            ? '（' +
                              imgSizeRightList[fileRightList[pageNum - 1].name].imgWidth +
                              'x' +
                              imgSizeRightList[fileRightList[pageNum - 1].name].imgHeight +
                              '像素，' +
                              imgSizeRightList[fileRightList[pageNum - 1].name].size +
                              '）'
                            : ''
                        }`
                      : ''
                  }}
                  <a-icon type="close" class="close-icon" @click="cencelOne('right')" />
                </div>
              </div>
              <div class="show-body" :style="'background-color:' + this.backGroundColor">
                <div class="show-img" @mouseover="imgRightScroll" @contextmenu.prevent="rightClick">
                  <a-upload-dragger
                    v-if="imgRights.length == 0"
                    name="file"
                    accept="image/*"
                    :showUploadList="false"
                    :multiple="true"
                    :before-upload="beforeRightUpload"
                    :disabled="!leftArr.length"
                    @change="handleRightChange"
                  >
                    <div>
                      <p class="ant-upload-drag-icon">
                        <a-icon type="folder-open" />
                      </p>
                      <p class="ant-upload-text">
                        将文件拖到此处，或点击该区域任意处打开
                      </p>
                      <p class="ant-upload-hint">
                        支持多张图片或单张图片上传
                      </p>
                    </div>
                  </a-upload-dragger>
                  <div v-else class="img-list">
                    <img v-for="item in imgRights" :key="item" :src="item" />
                    <div class="div-list" v-if="diffs.length > 0">
                      <div v-for="(data, i) in diffs" :key="i" :style="getSize(styleHeightList[i])">
                        <template v-if="data.rectangles != null">
                          <div
                            v-for="(item, index) in data.rectangles"
                            :ref="item.minPoint.y + '' + index"
                            :key="index"
                            class="diff-div"
                            :style="
                              `left:${item.minPoint.x}px;top:${item.minPoint.y}px;width:${item.maxPoint.x -
                                item.minPoint.x}px;height:${item.maxPoint.y -
                                item.minPoint.y}px;min-width:10px;min-height:10px;`
                            "
                          ></div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a-col>
          <a-col :span="openSpan[2]">
            <div class="show-div">
              <div class="show-head">
                <div class="name"></div>
              </div>
              <div class="span-body" style="background-color:#d9dce0;">
                <div style="font-size: 14px;padding: 12px 19px;">对比结果{{ getDiffLength() }}</div>
                <a-collapse v-if="diffs.length > 0" v-model="activeKey">
                  <template v-for="(item, index) in fileRightList">
                    <a-collapse-panel :key="index + ''" :header="item.name" v-if="item.rectangles != null">
                      <template v-if="item.rectangles">
                        <p
                          @click.self="skip(file, index, i)"
                          style="cursor: pointer;padding-left:24px;margin-bottom: 1vh;"
                          v-for="(file, i) in item.rectangles"
                          :key="i"
                        >
                          <a-icon
                            style="margin-right: 6px;padding-top:4px"
                            :type="isShow[index].children[i].isShow ? 'eye-invisible' : 'eye'"
                            @click.stop="showDiff(file, index, i)"
                          />
                          第{{ i + 1 }}处
                        </p>
                      </template>
                    </a-collapse-panel>
                  </template>
                </a-collapse>
              </div>
            </div>
          </a-col>
        </a-row>
      </transition>
    </div>
    <a-modal
      v-model="visible"
      @cancel="
        () => {
          this.visible = false
          this.errorList = [
            { title: '参考图片', list: [] },
            { title: '对比图片', list: [] }
          ]
        }
      "
      :width="350"
      centered
      okText="关闭"
    >
      <template slot="title">
        <div style="text-align:center;">
          <a-icon type="question-circle" style="color:#faad14" />
          <span style="padding-left:2px">提示</span>
        </div>
      </template>
      <div style="text-align:center">{{ modalTitle }}</div>
      <div
        style="justify-content: space-between;
                display: flex;
                width:100%;"
      >
        <div v-if="errorList[0].list.length > 0">
          <div style="padding:3px 0;font-weight:700;text-align:center;padding-top:17px">参考图片</div>
          <div style="padding:3px 0;text-align:center" v-for="item in errorList[0].list" :key="item">{{ item }}</div>
        </div>
        <div v-if="errorList[1].list.length > 0">
          <div style="padding:3px 0;font-weight:700;text-align:center;padding-top:17px">对比图片</div>
          <div style="padding:3px 0;text-align:center" v-for="item in errorList[1].list" :key="item">{{ item }}</div>
        </div>
      </div>
      <template slot="footer">
        <div style="text-align:center">
          <a-button
            key="back"
            @click="
              () => {
                this.visible = false
                this.errorList = [
                  { title: '参考图片', list: [] },
                  { title: '对比图片', list: [] }
                ]
              }
            "
          >
            取消
          </a-button>
          <a-button
            v-if="modalTitle == '两边图片尺寸一致，但文件名不一致。'"
            key="submit"
            type="primary"
            @click="getDiff()"
          >
            继续对比
          </a-button>
        </div>
      </template>
    </a-modal>
  </a-spin>
</template>

<script>
import { UploadFileToOssMixins } from '@/mixins/UploadFileToOssMixins'
import Vue from 'vue'
import { postAction, getAction } from '@/api/manage'
import { mapActions } from 'vuex'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import axios from 'axios'
let isLeftScroll = true
export default {
  mixins: [UploadFileToOssMixins],
  data() {
    return {
      logo: '../../assets/favicon.png',
      isSync: true,
      visible: false,
      modalTitle: '',
      screenWidth: document.documentElement.clientWidth,
      activeKey: [],
      errorList: [
        { title: '参考图片', list: [] },
        { title: '对比图片', list: [] }
      ],
      spinning: false,
      customStyle: {
        background: '#f7f7f7',
        marginBottom: 0,
        overflow: 'hidden'
      },
      sliderValue: 100,
      fileLeftList: [],
      fileRightList: [],
      isScroll: true,
      backGroundColor: '#a1a3a8',
      isOpen: true,
      isUp: true,
      isDown: true,
      openSpan: [10, 10, 4],
      openStyle: 0,
      avatar: '',
      imgLefts: [],
      imgRights: [],
      pageNum: 0,
      heightList: [0],
      styleHeightList: [],
      size: 'auto',
      formData: {
        pixelToleranceLevel: '15',
        threshold: '75'
      },
      diffs: [],
      oldWidth: 0,
      newWidth: 0,
      fileSizeList: [],
      imgSizeLeftList: {},
      imgSizeRightList: {},
      leftArr: [],
      rightArr: [],
      diffWidth: [],
      diffHeight: [],
      diffTop: [],
      diffLeft: [],
      isSlider: true,
      diffsCopy: [],
      scrollTop: 1,
      selectNum: 0,
      sliderScrollTop: 1,
      isShow: [],
      leftHeight: 0,
      rightHeight: 0,
      isSkip: false,
      oldLeftHeightList: [],
      oldRightHeightList: []
    }
  },
  created() {
    this.getBackGround()
  },
  mounted() {
    document.getElementsByClassName('show-img')[0].onscroll = () => {
        document.getElementsByClassName('show-img')[1].scrollTop = document.getElementsByClassName(
          'show-img'
        )[0].scrollTop
    }
    document.getElementsByClassName('show-img')[1].onscroll = () => {
        document.getElementsByClassName('show-img')[0].scrollTop = document.getElementsByClassName(
          'show-img'
        )[1].scrollTop
    }
    document.getElementsByClassName('show-img')[0].addEventListener('scroll', this.handleScroll)
    document.getElementsByClassName('show-img')[1].addEventListener('scroll', this.handleScroll)
    const that = this
    window.addEventListener('resize', () => {
      window.screenWidth = document.body.clientWidth
      that.screenWidth = window.screenWidth
    })
/*     window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        that.screenWidth = window.screenWidth
      })()
    } */
    this.timerFun()
  },
  watch: {
    oldLeftHeightList(val) {
      if (val.length && val.length == this.leftArr.length) {
        this.heightList = [0]
        this.leftHeight = 0
        this.leftArr.forEach((item, index) => {
          if (this.size == 'auto') {
            this.leftHeight = this.leftHeight + (item.imgHeight * this.sliderValue) / 100
            this.heightList.push(this.leftHeight)
          } else {
            this.leftHeight = this.leftHeight + item.imgHeight * this.size
            this.heightList.push(this.leftHeight)
          }
        })
        this.heightList.pop()
        this.leftHeight = 0
      }
    },
    oldRightHeightList(val) {
      if (val.length && val.length == this.leftArr.length) {
        this.heightList = [0]
        this.height = 0
        this.rightArr.forEach((item, index) => {
          if (this.size == 'auto') {
            this.height = this.height + (item.imgHeight * this.sliderValue) / 100
            this.heightList.push(this.height)
          } else {
            this.height = this.height + item.imgHeight * this.size
            this.heightList.push(this.height)
          }
        })
        this.heightList.pop()
        this.height = 0
      }
    },
    pageNum(val) {
      if (this.isUp && this.isDown) {
        if (val > 0) {
          if (val <= this.imgLefts.length && document.getElementsByClassName('img-list')[0]?.childNodes) {
            if (this.heightList.length == 1) {
              let height = 0
              document.getElementsByClassName('img-list')[0].childNodes.forEach(item => {
                height = height + item.height
                this.heightList.push(height)
                this.styleHeightList.push(item.height)
              })
            }
            this.$nextTick(() => {
              if (
                document.getElementsByClassName('img-list')[0].lastChild.height <
                document.getElementsByClassName('show-img')[0].clientHeight
              ) {
                let num =
                  document.getElementsByClassName('show-img')[0].clientHeight -
                  document.getElementsByClassName('img-list')[0].lastChild.height
                this.heightList[this.heightList.length - 1] = this.heightList[this.heightList.length - 1] - num / 2
              }
              document.getElementsByClassName('show-img')[0].scrollTop = this.heightList[val - 1]
              document.getElementsByClassName('show-img')[1].scrollTop = this.heightList[val - 1]
              return
            })
          }
          if (val <= this.imgRights.length && document.getElementsByClassName('img-list')[0]?.childNodes) {
            if (this.heightList.length == 1) {
              let height = 0
              document.getElementsByClassName('img-list')[0].childNodes.forEach(item => {
                height = height + item.height
                this.heightList.push(height)
                this.styleHeightList.push(item.height)
              })
            }
            this.$nextTick(() => {
              if (
                document.getElementsByClassName('img-list')[0].lastChild.height <
                document.getElementsByClassName('show-img')[0].clientHeight
              ) {
                let num =
                  document.getElementsByClassName('show-img')[0].clientHeight -
                  document.getElementsByClassName('img-list')[0].lastChild.height
                this.heightList[this.heightList.length - 1] = this.heightList[this.heightList.length - 1] - num / 2
              }
              document.getElementsByClassName('show-img')[0].scrollTop = this.heightList[val - 1]
              document.getElementsByClassName('show-img')[1].scrollTop = this.heightList[val - 1]
            })
          }
        }
      }
      this.isUp = true
      this.isDown = true
    },
    imgLefts(val) {
      if (val.length > 0) {
        setTimeout(() => {
          this.heightList = [0]
          this.styleHeightList = []
          let height = 0
          document.getElementsByClassName('img-list')[0].childNodes.forEach(item => {
            height = height + item.height
            this.heightList.push(height)
            this.styleHeightList.push(item.height)
          })
          this.$nextTick(() => {
            if (
              document.getElementsByClassName('img-list')[0].lastChild.clientHeight <
              document.getElementsByClassName('show-img')[0].clientHeight
            ) {
              let num =
                document.getElementsByClassName('show-img')[0].clientHeight -
                document.getElementsByClassName('img-list')[0].lastChild.clientHeight
              this.heightList[this.heightList.length - 1] = this.heightList[this.heightList.length - 1] - num / 2
            }
            document.getElementsByClassName('show-img')[0].scrollTop = this.heightList[this.pageNum - 1]
            document.getElementsByClassName('show-img')[1].scrollTop = this.heightList[this.pageNum - 1]
          })
        }, 2500)
      }
    },
    imgRights(val) {
      if (val.length > 0) {
        setTimeout(() => {
          this.heightList = [0]
          this.styleHeightList = []
          let height = 0
          document.getElementsByClassName('img-list')[0].childNodes.forEach(item => {
            height = height + item.height
            this.heightList.push(height)
            this.styleHeightList.push(item.height)
          })
          this.$nextTick(() => {
            if (
              document.getElementsByClassName('img-list')[1].lastChild.height <
              document.getElementsByClassName('show-img')[1].clientHeight
            ) {
              let num =
                document.getElementsByClassName('show-img')[1].clientHeight -
                document.getElementsByClassName('img-list')[1].lastChild.height
              this.heightList[this.heightList.length - 1] = this.heightList[this.heightList.length - 1] - num / 2
            }
            document.getElementsByClassName('show-img')[0].scrollTop = this.heightList[this.pageNum - 1]
            document.getElementsByClassName('show-img')[1].scrollTop = this.heightList[this.pageNum - 1]
          })
        }, 2500)
      }
    },
    fileLeftList(val) {
      if (val.length > 0) {
        this.$nextTick(() => {
          document.getElementsByClassName('img-list')[0].style.opacity = '0.01'
        })
        if (this.sliderValue != 100) {
          setTimeout(() => {
            if (document.getElementsByClassName('img-list')[0].length == 1) {
              document.getElementsByClassName('img-list')[0].childNodes.forEach(item => {
                item.style.width = this.sliderValue + '%'
              })
            } else {
              document.getElementsByClassName('img-list')[0].childNodes.forEach(item => {
                item.style.width = this.sliderValue + '%'
              })
              document.getElementsByClassName('img-list')[1].childNodes.forEach(item => {
                item.style && (item.style.width = this.sliderValue + '%')
              })
            }
          }, 2000)
        }
        setTimeout(() => {
          document.getElementsByClassName('img-list')[0].style.opacity = '1'
        }, 2000)
      }
    },
    fileRightList(val) {
      if (val.length > 0) {
        this.$nextTick(() => {
          document.getElementsByClassName('img-list')[1].style.opacity = '0.01'
        })
        if (this.sliderValue != 100) {
          setTimeout(() => {
            if (document.getElementsByClassName('img-list')[0].length == 1) {
              document.getElementsByClassName('img-list')[0].childNodes.forEach(item => {
                item.style.width = this.sliderValue + '%'
              })
            } else {
              document.getElementsByClassName('img-list')[0].childNodes.forEach(item => {
                item.style.width = this.sliderValue + '%'
              })
              document.getElementsByClassName('img-list')[1].childNodes.forEach(item => {
                item.style && (item.style.width = this.sliderValue + '%')
              })
            }
          }, 2000)
        }
        setTimeout(() => {
          document.getElementsByClassName('img-list')[1].style.opacity = '1'
        }, 2000)
      }
    }
  },
  methods: {
    ...mapActions(['Logout']),
    goToHome() {
      window.location.href = '/'
    },
    timerFun() {
      this.stopTimer = false
      let myTimer = setInterval(async () => {
        // 停止定时器
        if (this.stopTimer == true) {
          clearInterval(myTimer)
          return
        }
        await getAction('/sys/checkLogin')
      }, 6000)
    },
    async getBackGround() {
      try {
        const res = await getAction('/theme/query')
        if (res.msg == 'SUCCESSFUL') {
          res.data.forEach(item => {
            if (item.type == 'LOGO') {
              this.logo = item.value
            }
          })
        } else {
          this.$message.error(res.msg || res.message)
        }
      } catch (err) {
        console.log(err)
      }
    },
    handleLogout() {
      const that = this
      this.$confirm({
        title: '提示',
        content: '真的要注销登录吗 ?',
        onOk() {
          return that
            .Logout({})
            .then(() => {
              // update-begin author:wangshuai date:20200601 for: 退出登录跳转登录页面
              let editorOrigin = Vue.ls.get('EDITOR_ORIGIN')
              if (editorOrigin) {
                try {
                  let getUrl = editorOrigin + '/sso/logout'
                  console.log(getUrl)
                  let iframe = document.createElement('iframe')
                  iframe.style = 'display: none'
                  iframe.src = getUrl
                  iframe.onload = function() {
                    /* window.location.href = toEditor; */
                    document.body.removeChild(iframe)
                  }
                  document.body.appendChild(iframe)
                  // axios.get(getUrl, {
                  //   withCredentials: true,
                  // }).then(data => {
                  // }).catch(e => {
                  //   console.error(e);
                  // });
                } catch (e) {}
              }
              that.$router.push({ path: '/user/login' })
              // update-end author:wangshuai date:20200601 for: 退出登录跳转登录页面
              //window.location.reload()
            })
            .catch(err => {
              that.$message.error({
                title: '错误',
                description: err.message
              })
            })
        },
        onCancel() {}
      })
    },
    getSize(height) {
      let size = document.getElementsByClassName('img-list')[1].childNodes[0].style.width
      if (this.isSlider) {
        return 'height:' + height + 'px;width:' + this.sliderValue + '%;position: relative;margin: 0 auto;'
      } else {
        return 'height:' + height + 'px;width:' + size + ';position: relative;margin: 0 auto;'
      }
    },
    skip(file, i, index) {
      this.isUp = false
      this.isDown = false
      this.isSkip = true
      let y = 0
      if (this.sliderValue == 100) {
        y = file.minPoint.y
      } else {
        let scale = '0.' + this.sliderValue - 0
        y = file.minPoint.y * scale
      }
      let height = 0
      this.styleHeightList.forEach((item, itemIndex) => {
        if (itemIndex <= i && itemIndex > 0) {
          height = height + this.styleHeightList[itemIndex - 1]
        }
      })
      document.getElementsByClassName('show-img')[0].scrollTop = y - 200 + height
      document.getElementsByClassName('show-img')[1].scrollTop = y - 200 + height
      let arr = Array.from(document.getElementsByClassName('diff-div'))
      arr.forEach(item => {
        item.style.border = '2px solid orange'
      })
      this.$refs[file.minPoint.y + '' + index][0].style.borderColor = 'red'
      this.pageNum = i + 1
      setTimeout(() => {
        this.isSkip = false
      }, 500)
    },
    showDiff(file, i, index) {
      if (this.$refs[file.minPoint.y + '' + index][0].style.display == 'none') {
        this.$refs[file.minPoint.y + '' + index][0].style.display = ''
        this.isShow[i].children[index].isShow = false
      } else {
        this.$refs[file.minPoint.y + '' + index][0].style.display = 'none'
        this.isShow[i].children[index].isShow = true
      }
    },
    getDiffLength() {
      let text = ''
      let num = 0
      this.fileRightList.forEach(item => {
        if (item.rectangles) {
          num = num + item.rectangles.length
        }
      })
      if (num != 0) {
        text = '（共' + num + '处）'
      }
      return text
    },
    async diff() {
      if (this.fileLeftList.length == 1 && this.fileRightList.length == 1) {
        /*         if (this.fileRightList[0].size == this.fileLeftList[0].size) {
          this.$message.error('两边图片一致,无需对比')
          return
        } */
      }
      if (this.imgLefts.length != this.imgRights.length) {
        this.visible = true
        this.modalTitle = '两边图片数量不一致，请补齐后再对比。'
        return
      }
      let isName = true
      let isSize = true
      document.getElementsByClassName('img-list')[0].childNodes.forEach((item, index) => {
        document.getElementsByClassName('img-list')[1].childNodes.forEach((data, i) => {
          if (item.width != data.width && index == i) {
            isSize = false
            this.errorList[0].list.push(this.fileLeftList[index].name)
            this.errorList[1].list.push(this.fileRightList[i].name)
          } else if (item.height != data.height && index == i) {
            isSize = false
            this.errorList[0].list.push(this.fileLeftList[index].name)
            this.errorList[1].list.push(this.fileRightList[i].name)
          }
        })
      })
      if (isSize) {
        this.fileLeftList.forEach((item, index) => {
          this.fileRightList.forEach((file, i) => {
            if (item.name != file.name && index == i) {
              isName = false
              this.errorList[0].list.push(item.name)
              this.errorList[1].list.push(file.name)
            }
          })
        })
      }
      if (!isSize) {
        this.visible = true
        this.modalTitle = '两边图片尺寸不一致，请核对后再对比。'
        return
      } else if (isSize && !isName) {
        this.visible = true
        this.modalTitle = '两边图片尺寸一致，但文件名不一致。'
        return
      } else {
        this.getDiff()
      }
    },
    async forI(fileLeftList, fileRightList, formData, fileSizeList) {
      let that = this
      for (var index = 0; index < fileLeftList.length; index++) {
        let uid = fileLeftList[index].uid
        let fileLeft = {}
        fileLeft = await that.compressImg(fileLeftList[index])
        fileLeft.uid = uid
        formData.append('sourceFiles', fileLeft)
      }
      for (var i = 0; i < fileRightList.length; i++) {
        let id = fileRightList[i].uid
        let fileRight = {}
        fileRight = await that.compressImg(fileRightList[i])
        fileRight.uid = id

        formData.append('targetFiles', fileRight)
      }
    },
    async getDiff() {
      this.fileSizeList = []
      this.visible = false
      this.spinning = true
      let formData = new FormData()
      await this.forI(this.fileLeftList, this.fileRightList, formData, this.fileSizeList)
      /*       this.fileLeftList.forEach((item, index) => {
        this.fileRightList.forEach(async (file, i) => {
          if (index == i && item.size != file.size) {
            formData.append('sourceFiles', item)
            formData.append('targetFiles', file)
          } else if (index == i && item.size == file.size) {
            this.fileSizeList.push(index)
          }
        })
      }) */
      formData.append('pixelToleranceLevel', this.formData.pixelToleranceLevel / 100)
      formData.append('threshold', this.formData.threshold)
      formData.append('minimalRectangleSize', '50')
      if (!this.formData.pixelToleranceLevel > 0 && !this.formData.pixelToleranceLevel <= 10) {
        formData.append('pixelToleranceLevel', '15')
      }
      if (!this.formData.threshold > 0) {
        formData.append('threshold', '75')
      }
      /* formData.append("needFile",true) */
      this.diffsCopy = []
      this.diffs = []
      postAction('/compare/compare', formData).then(res => {
        /* this.diffs = res.data.data */
        this.diffs = res.data
        console.log(this.diffs)
        if (
          this.diffs.some(item => {
            return item.imageComparisonState == 'SIZE_MISMATCH'
          })
        ) {
          this.$message.error('文件尺寸不匹配')
          this.spinning = false
          return
        } else {
          this.diffsCopy = []
          this.isShow = []
          document.getElementsByClassName('img-list')[0].childNodes[0].style.width = 'auto'
          this.oldWidth = document.getElementsByClassName('img-list')[0].childNodes[0].width
          document.getElementsByClassName('img-list')[0].childNodes[0].style.width = '100%'
          this.newWidth = document.getElementsByClassName('img-list')[0].childNodes[0].width
          let scale = Number(this.oldWidth / this.newWidth).toFixed(2)
          scale = scale - 0
          this.fileSizeList.forEach(fileIndex => {
            this.diffs.splice(fileIndex, 0, { rectangles: null, imageComparisonState: 'MATCH' })
          })
          this.diffs.forEach((item, index) => {
            let copyItem = JSON.stringify(item)
            copyItem = JSON.parse(copyItem)
            this.diffsCopy.push(copyItem)
            let children = []
            item.rectangles?.forEach(data => {
              data.maxPoint.x = ((data.maxPoint.x * 2) / scale).toFixed(0)
              data.maxPoint.y = ((data.maxPoint.y * 2) / scale + 20).toFixed(0)
              data.minPoint.x = ((data.minPoint.x * 2) / scale).toFixed(0)
              data.minPoint.y = ((data.minPoint.y * 2) / scale).toFixed(0)
              children.push({ isShow: false })
            })
            this.activeKey = []
            this.fileRightList.forEach((file, i) => {
              if (index == i) {
                file.rectangles = item.rectangles
              }
              this.activeKey.push(i + '')
            })
            this.isShow.push({ children })
          })
          this.$nextTick(() => {
            document.querySelectorAll('.diff-div').forEach(item => {
              this.diffWidth.push(item.style.width.split('px')[0])
              this.diffHeight.push(item.style.height.split('px')[0])
              this.diffTop.push(item.style.top.split('px')[0])
              this.diffLeft.push(item.style.left.split('px')[0])
            })
          })
          if (this.size != 'auto') {
            this.handleSelect()
          } else {
            this.sliderChange()
          }
          this.$message.success('对比成功')
        }
        this.errorList = [
          { title: '参考图片', list: [] },
          { title: '对比图片', list: [] }
        ]
        this.spinning = false
      })
    },
    cencelOne(type) {
      if (type == 'left') {
        if (this.imgRights.length) {
          this.$message.error('请先清除对比图片')
          return
        }
        this.imgLefts = []
        this.fileLeftList = []
        this.leftArr = []
        this.imgSizeLeftList = []
        if (this.imgRights.length == 0) {
          this.pageNum = 0
        }
      } else {
        this.imgRights = []
        this.fileRightList = []
        this.rightArr = []
        this.imgSizeRightList = []
        if (this.imgLefts.length == 0) {
          this.pageNum = 0
        }
      }
      this.diffWidth = []
      this.diffHeight = []
      this.diffTop = []
      this.diffLeft = []
      this.heightList = [0]
      this.imgSizeLeftList = []
      this.styleHeightList = []
    },
    cencel() {
      let pixelToleranceLevel = this.formData.pixelToleranceLevel
      let threshold = this.formData.threshold
      Object.assign(this._data, this.$options.data())
      this.formData = {
        pixelToleranceLevel,
        threshold
      }
    },
    sliderChange() {
      this.isSlider = true
      this.isDown = false
      this.isUp = false
      this.size = 'auto'
      this.heightList = [0]
      let scale = '0.' + this.sliderValue - 0
      let top = document.getElementsByClassName('show-img')[0].scrollTop / this.sliderScrollTop
      if (this.diffWidth.length > 0) {
        if (this.sliderValue < 100) {
          document.querySelectorAll('.diff-div').forEach((item, index) => {
            this.diffWidth.forEach((data, i) => {
              if (i == index) {
                item.style.width = data * scale + 'px'
                item.style.height = this.diffHeight[i] * scale + 'px'
                item.style.top = this.diffTop[i] * scale + 'px'
                item.style.left = this.diffLeft[i] * scale + 'px'
              }
            })
          })
        } else {
          document.querySelectorAll('.diff-div').forEach((item, index) => {
            this.diffWidth.forEach((data, i) => {
              if (i == index) {
                item.style.width = data + 'px'
                item.style.height = this.diffHeight[i] + 'px'
                item.style.top = this.diffTop[i] + 'px'
                item.style.left = this.diffLeft[i] + 'px'
              }
            })
          })
        }
      }
      if (
        document.getElementsByClassName('img-list').length > 0 &&
        document.getElementsByClassName('img-list').length <= 1
      ) {
        document.getElementsByClassName('img-list')[0].childNodes.forEach(item => {
          item.style.width = this.sliderValue + '%'
        })
      } else if (document.getElementsByClassName('img-list').length > 1) {
        document.getElementsByClassName('img-list')[0].childNodes.forEach(item => {
          item.style.width = this.sliderValue + '%'
        })
        document.getElementsByClassName('img-list')[1].childNodes.forEach(item => {
          item.style && (item.style.width = this.sliderValue + '%')
        })
      }
      this.$nextTick(() => {
        let height = 0
        this.heightList = [0]
        this.styleHeightList = []
        document.getElementsByClassName('img-list')[0].childNodes.forEach(item => {
          height = height + item.height
          this.heightList.push(height)
          this.styleHeightList.push(item.height)
        })
        if (
          document.getElementsByClassName('img-list')[0].lastChild.height <
          document.getElementsByClassName('show-img')[0].clientHeight
        ) {
          let num =
            document.getElementsByClassName('show-img')[0].clientHeight -
            document.getElementsByClassName('img-list')[0].lastChild.height
          this.heightList[this.heightList.length - 1] = this.heightList[this.heightList.length - 1] - num / 2
        }
      })
      this.$nextTick(() => {
        if (this.sliderValue != 100) {
          document.getElementsByClassName('show-img')[0].scrollTop = scale * top
          document.getElementsByClassName('show-img')[1].scrollTop = scale * top
          this.sliderScrollTop = scale
        } else {
          document.getElementsByClassName('show-img')[0].scrollTop = top
          document.getElementsByClassName('show-img')[1].scrollTop = top
          this.sliderScrollTop = 1
        }
      })
    },
    handleSelect() {
      this.isSlider = false
      this.isDown = false
      this.isUp = false
      this.sliderValue = 100
      this.heightList = [0]
      let scale = this.size - 0
      let top = 0
      if (this.scrollTop == 'auto') {
        document.getElementsByClassName('img-list')[0].childNodes[0].style.width = 'auto'
        let oldWidth = document.getElementsByClassName('img-list')[0].childNodes[0].width
        document.getElementsByClassName('img-list')[0].childNodes[0].style.width = '100%'
        let newWidth = document.getElementsByClassName('img-list')[0].childNodes[0].width
        let scale = Number(oldWidth / newWidth).toFixed(2)
        top = document.getElementsByClassName('show-img')[0].scrollTop * scale
      } else {
        top = document.getElementsByClassName('show-img')[0].scrollTop / this.scrollTop
      }
      if (this.size != 'auto') {
        this.diffs = []
        let num = 20 * scale
        this.diffsCopy.forEach((item, index) => {
          let copyItem = JSON.stringify(item)
          copyItem = JSON.parse(copyItem)
          this.diffs.push(copyItem)
        })
        this.diffs.forEach((item, index) => {
          item.rectangles?.forEach(data => {
            data.maxPoint.x = (data.maxPoint.x * 2 * scale).toFixed(0)
            data.maxPoint.y = (data.maxPoint.y * 2 * scale).toFixed(0)
            data.minPoint.x = (data.minPoint.x * 2 * scale).toFixed(0)
            data.minPoint.y = (data.minPoint.y * 2 * scale).toFixed(0)
          })
          this.fileRightList.forEach((file, i) => {
            if (index == i) {
              file.rectangles = item.rectangles
            }
          })
        })
      } else if (this.size == 'auto') {
        this.diffs = []
        this.diffsCopy.forEach((item, index) => {
          let copyItem = JSON.stringify(item)
          copyItem = JSON.parse(copyItem)
          this.diffs.push(copyItem)
        })
        document.getElementsByClassName('img-list')[0].childNodes[0].style.width = 'auto'
        this.oldWidth = document.getElementsByClassName('img-list')[0].childNodes[0].width
        document.getElementsByClassName('img-list')[0].childNodes[0].style.width = '100%'
        this.newWidth = document.getElementsByClassName('img-list')[0].childNodes[0].width
        let scale = Number(this.oldWidth / this.newWidth).toFixed(2)
        if (this.oldWidth > this.newWidth) {
          this.diffs.forEach((item, index) => {
            item.rectangles?.forEach(data => {
              data.maxPoint.x = ((data.maxPoint.x * 2) / scale).toFixed(0)
              data.maxPoint.y = ((data.maxPoint.y * 2) / scale).toFixed(0)
              data.minPoint.x = ((data.minPoint.x * 2) / scale).toFixed(0)
              data.minPoint.y = ((data.minPoint.y * 2) / scale).toFixed(0)
            })
            this.activeKey = []
            this.fileRightList.forEach((file, i) => {
              if (index == i) {
                file.rectangles = item.rectangles
              }
              this.activeKey.push(i + '')
            })
          })
        } else {
          this.diffs.forEach((item, index) => {
            item.rectangles?.forEach(data => {
              data.maxPoint.x = ((data.maxPoint.x * 2) / scale).toFixed(0)
              data.maxPoint.y = ((data.maxPoint.y * 2) / scale).toFixed(0)
              data.minPoint.x = ((data.minPoint.x * 2) / scale).toFixed(0)
              data.minPoint.y = ((data.minPoint.y * 2) / scale).toFixed(0)
            })
            this.activeKey = []
            this.fileRightList.forEach((file, i) => {
              if (index == i) {
                file.rectangles = item.rectangles
              }
              this.activeKey.push(i + '')
            })
          })
        }
      }
      if (document.getElementsByClassName('img-list').length > 0) {
        document.getElementsByClassName('img-list')[0].childNodes.forEach(item => {
          if (this.size != 'auto') {
            item.style.width = 'auto'
            item.style.width = item.width * this.size + 'px'
          } else {
            item.style.width = '100%'
          }
        })
      }
      if (document.getElementsByClassName('img-list').length > 1) {
        document.getElementsByClassName('img-list')[1].childNodes.forEach(item => {
          if (this.size != 'auto') {
            if (item.width) {
              item.style.width = 'auto'
              item.style.width = item.width * this.size + 'px'
            }
          } else {
            if (item.width) item.style.width = '100%'
          }
        })
      }
      this.$nextTick(() => {
        let height = 0
        this.heightList = [0]
        this.styleHeightList = []
        document.getElementsByClassName('img-list')[0].childNodes.forEach(item => {
          height = height + item.height
          this.heightList.push(height)
          this.styleHeightList.push(item.height)
        })
        if (
          document.getElementsByClassName('img-list')[0].lastChild.height <
          document.getElementsByClassName('show-img')[0].clientHeight
        ) {
          let num =
            document.getElementsByClassName('show-img')[0].clientHeight -
            document.getElementsByClassName('img-list')[0].lastChild.height
          this.heightList[this.heightList.length - 1] = this.heightList[this.heightList.length - 1] - num / 2
        }
      })
      this.$nextTick(() => {
        if (this.size != 'auto') {
          document.getElementsByClassName('show-img')[0].scrollTop = this.size * top
          document.getElementsByClassName('show-img')[1].scrollTop = this.size * top
          this.scrollTop = this.size
        } else {
          document.getElementsByClassName('img-list')[0].childNodes[0].style.width = 'auto'
          let oldWidth = document.getElementsByClassName('img-list')[0].childNodes[0].width
          document.getElementsByClassName('img-list')[0].childNodes[0].style.width = '100%'
          let newWidth = document.getElementsByClassName('img-list')[0].childNodes[0].width
          let scale = Number(oldWidth / newWidth).toFixed(2)
          if (scale > 1) {
            document.getElementsByClassName('show-img')[0].scrollTop = top / scale
            document.getElementsByClassName('show-img')[1].scrollTop = top / scale
          } else {
            document.getElementsByClassName('show-img')[0].scrollTop = top / scale
            document.getElementsByClassName('show-img')[1].scrollTop = top / scale
          }
          this.scrollTop = this.size
        }
      })
    },
    handleScroll(e) {
      setTimeout(() => {
        if (!this.isSkip) {
          if (e.target.scrollTop > this.heightList[this.pageNum]) {
            this.isDown = false
            this.pageNum++
          } else if (e.target.scrollTop < this.heightList[this.pageNum - 1] - 1 && this.pageNum > 1) {
            this.isUp = false
            this.pageNum--
          }
        }
        this.isSkip = false
      }, 100)
    },
    pageChange() {},
    handleLeftChange(info) {
      this.leftArr.push(info)
      if (this.leftArr.length == info.fileList.length) {
        this.fileLeftList.forEach(a => {
          if (a.name.split('.')[0][a.name.split('.')[0].length - 1] - 0 + '' == 'NaN') {
            this.$message.error(a.name + '文件名不是以数字结尾')
          }
        })
        this.leftArr.forEach((item, i) => {
          this.fileLeftList.forEach((data, index) => {
            if (i == index) {
              const url = window.URL ? window.URL.createObjectURL(data) : window.webkitURL.createObjectURL(data)
              item.url = url
              let reader = new FileReader()
              reader.readAsDataURL(data)
              reader.onload = async () => {
                const image = new Image()
                image.src = reader.result
                image.onload = () => {
                  item.imgWidth = image.width
                  item.imgHeight = image.height
                  let obj = {}
                  let imgSize = (item.file.size / 1024 / 1024).toFixed(2) + 'M'
                  if (this.size == 'auto') {
                    this.leftHeight = this.leftHeight + (item.imgHeight * this.sliderValue) / 100
                    this.oldLeftHeightList.push(this.leftHeight)
                  } else {
                    this.leftHeight = this.leftHeight + item.imgHeight * this.size
                    this.oldLeftHeightList.push(this.leftHeight)
                  }
                  if (document.getElementsByClassName('img-list').length > 0) {
                    document.getElementsByClassName('img-list')[0].childNodes?.forEach(dom => {
                      if (this.size != 'auto') {
                        dom.style.width = 'auto'
                        dom.style.width = item.imgWidth * this.size + 'px'
                      } else {
                        dom.style.width = '100%'
                      }
                    })
                  }
                  if (document.getElementsByClassName('img-list').length > 1) {
                    document.getElementsByClassName('img-list')[1].childNodes?.forEach(dom => {
                      if (this.size != 'auto') {
                        if (dom.width) {
                          dom.style.width = 'auto'
                          dom.style.width = item.imgWidth * this.size + 'px'
                        }
                      } else {
                        if (dom.width) dom.style.width = '100%'
                      }
                    })
                  }
                  if (this.oldLeftHeightList.length == this.fileLeftList.length + 1) {
                    /* this.heightList.pop() */
                    this.leftHeight = 0
                  }
                  obj[item.file.name] = { imgWidth: item.imgWidth, imgHeight: item.imgHeight, size: imgSize }
                  this.imgSizeLeftList = Object.assign({}, this.imgSizeLeftList, obj)
                  this.$forceUpdata()
                }
              }
            }
          })

          this.imgLefts.push(item.url)
        })
        /* this.leftArr.forEach((item,index) => {
          this.imgLefts.push(item.url)
        }) */
        console.log(this.heightList, 123)
        this.openStyle = 0
        this.pageNum = 1
      }
    },
    handleRightChange(info) {
      this.rightArr.push(info)
      if (this.rightArr.length == info.fileList.length) {
        this.fileRightList.forEach(a => {
          if (a.name.split('.')[0][a.name.split('.')[0].length - 1] - 0 + '' == 'NaN') {
            this.$message.error(a.name + '文件名不是以数字结尾')
          }
        })
        this.rightArr.forEach((item, i) => {
          this.fileRightList.forEach((data, index) => {
            if (i == index) {
              const url = window.URL ? window.URL.createObjectURL(data) : window.webkitURL.createObjectURL(data)
              item.url = url
              let reader = new FileReader()
              reader.readAsDataURL(data)
              reader.onload = async () => {
                const image = new Image()
                image.src = reader.result
                image.onload = () => {
                  item.imgWidth = image.width
                  item.imgHeight = image.height
                  let obj = {}
                  let imgSize = (item.file.size / 1024 / 1024).toFixed(2) + 'M'
                  if (this.size == 'auto') {
                    this.height = this.height + (item.imgHeight * this.sliderValue) / 100
                    /* this.heightList.push(this.height) */
                    this.oldRightHeightList.push(this.height)
                  } else {
                    this.height = this.height + item.imgHeight * this.size
                    /* this.heightList.push(this.height) */
                    this.oldRightHeightList.push(this.height)
                  }
                  if (document.getElementsByClassName('img-list').length > 0) {
                    document.getElementsByClassName('img-list')[0].childNodes?.forEach(dom => {
                      if (this.size != 'auto') {
                        dom.style.width = 'auto'
                        dom.style.width = item.imgWidth * this.size + 'px'
                      } else {
                        dom.style.width = '100%'
                      }
                    })
                  }
                  if (document.getElementsByClassName('img-list').length > 1) {
                    document.getElementsByClassName('img-list')[1].childNodes?.forEach(dom => {
                      if (this.size != 'auto') {
                        if (dom.width) {
                          dom.style.width = 'auto'
                          dom.style.width = item.imgWidth * this.size + 'px'
                        }
                      } else {
                        if (dom.width) dom.style.width = '100%'
                      }
                    })
                  }
                  if (this.heightList.length == this.fileRightList.length + 1) {
                    /* this.heightList.pop() */
                    this.height = 0
                  }
                  obj[item.file.name] = { imgWidth: item.imgWidth, imgHeight: item.imgHeight, size: imgSize }
                  this.imgSizeRightList = Object.assign({}, this.imgSizeRightList, obj)
                }
              }
            }
          })
        })
        this.rightArr.forEach(item => {
          this.imgRights.push(item.url)
        })
        this.openStyle = 0
        this.pageNum = 1
      }
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file)
      const newFileList = this.fileList.slice()
      newFileList.splice(index, 1)
      this.fileList = newFileList
    },
    beforeLeftUpload(file, e) {
      this.fileLeftList = [...this.fileLeftList, file]
      let isNumber = x => !isNaN(Number(x))
      this.fileLeftList.sort((a, b) => {
        let na = a.name.split(/[-_.—, ]/)
        let nb = b.name.split(/[-_.—, ]/)
        let maxLoop = Math.max(na.length, nb.length)
        for (let i = 0; i < maxLoop; i++) {
          if (isNumber(na[i]) && isNumber(nb[i])) {
            let num = Number(na[i]) - Number(nb[i])
            if (num !== 0) {
              return num
            }
          }
          let num = na[i].localeCompare(nb[i])
          if (num !== 0) {
            return num
          }
        }
        return 0
      })
      return false
    },
    beforeRightUpload(file, e) {
      this.fileRightList = [...this.fileRightList, file]
      let isNumber = x => !isNaN(Number(x))
      this.fileRightList.sort((a, b) => {
        let na = a.name.split(/[-_.—, ]/)
        let nb = b.name.split(/[-_.—, ]/)
        let maxLoop = Math.max(na.length, nb.length)
        for (let i = 0; i < maxLoop; i++) {
          if (isNumber(na[i]) && isNumber(nb[i])) {
            let num = Number(na[i]) - Number(nb[i])
            if (num !== 0) {
              return num
            }
          }
          let num = na[i].localeCompare(nb[i])
          if (num !== 0) {
            return num
          }
        }
        return 0
      })
      return false
    },
    compressImg(file) {
      const that = this
      var files
      var fileSize = parseFloat(parseInt(file['size']) / 1024 / 1024).toFixed(2)
      var read = new FileReader()
      read.readAsDataURL(file)
      return new Promise(function(resolve, reject) {
        read.onload = function(e) {
          var img = new Image()
          img.src = e.target.result
          img.onload = function() {
            // 默认按比例压缩
            var w = this.width / 2
            var h = this.height / 2
            w = w.toFixed(2)
            h = h.toFixed(2)
            // 生成canvas
            var canvas = document.createElement('canvas')
            var ctx = canvas.getContext('2d')
            var base64
            // 创建属性节点
            canvas.setAttribute('width', w)
            canvas.setAttribute('height', h)
            ctx.drawImage(this, 0, 0, w, h)
            if (fileSize < 1) {
              // 如果图片小于一兆 那么压缩0.5
              base64 = canvas.toDataURL(file['type'], 0.5)
            } else if (fileSize > 1 && fileSize < 2) {
              // 如果图片大于1M并且小于2M 那么压缩0.5
              base64 = canvas.toDataURL(file['type'], 0.5)
            } else {
              // 如果图片超过2m 那么压缩0.2
              base64 = canvas.toDataURL(file['type'], 0.2)
            }
            // 回调函数返回file的值（将base64编码转成file）
            files = that.dataURLtoFile(base64, file.name) // 如果后台接收类型为base64的话这一步可以省略
            resolve(files)
          }
        }
      })
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    open() {
      this.isOpen = false
      this.openStyle = 0
      this.openSpan = [24, 0, 0]
      setTimeout(() => {
        let height = 0
        this.heightList = [0]
        this.styleHeightList = []
        if (this.imgLefts.length) {
          document.getElementsByClassName('img-list')[0].childNodes.forEach(item => {
            height = height + item.height
            this.heightList.push(height)
            this.styleHeightList.push(item.height)
          })
          if (
            document.getElementsByClassName('img-list')[0].lastChild.height <
            document.getElementsByClassName('show-img')[0].clientHeight
          ) {
            let num =
              document.getElementsByClassName('show-img')[0].clientHeight -
              document.getElementsByClassName('img-list')[0].lastChild.height
            this.heightList[this.heightList.length - 1] = this.heightList[this.heightList.length - 1] - num / 2
          }
        }
      }, 500)
    },
    close() {
      this.isOpen = true
      this.openStyle = 0
      this.openSpan = [10, 10, 4]
      setTimeout(() => {
        let height = 0
        this.heightList = [0]
        this.styleHeightList = []
        document.getElementsByClassName('img-list')[0].childNodes.forEach(item => {
          height = height + item.height
          this.heightList.push(height)
          this.styleHeightList.push(item.height)
        })
        if (
          document.getElementsByClassName('img-list')[0].lastChild.height <
          document.getElementsByClassName('show-img')[0].clientHeight
        ) {
          let num =
            document.getElementsByClassName('show-img')[0].clientHeight -
            document.getElementsByClassName('img-list')[0].lastChild.height
          this.heightList[this.heightList.length - 1] = this.heightList[this.heightList.length - 1] - num / 2
        }
      }, 300)
    },
    rightClick() {
      this.$contextmenu({
        items: [
          {
            label: '黑色',
            onClick: () => {
              this.backGroundColor = 'black'
            }
          },
          {
            label: '白色',
            onClick: () => {
              this.backGroundColor = '#fff'
            }
          },
          {
            label: '灰色',
            onClick: () => {
              this.backGroundColor = '#a1a3a8'
            }
          }
        ],
        event,
        customClass: 'resource-context-menu',
        zIndex: 999,
        minWidth: 80
      })
    },
    imgLeftScroll(e) {
      isLeftScroll = true
      this.openStyle = 0
    },
    imgRightScroll(e) {
      isLeftScroll = false
      this.openStyle = 0.9
    },
    checkChange(e) {}
  }
}
</script>

<style lang="less" scoped>
@import '../../styles/inspect.less';
/deep/ .ant-collapse-content > .ant-collapse-content-box {
  padding: 16px;
  padding-top: 0;
  padding-bottom: 0;
}
/deep/ .ant-modal-body {
  padding: 24px 45px;
}
/deep/ .ant-spin-nested-loading {
  height: 100% !important;
}
/deep/ .ant-spin-container {
  height: 100% !important;
}
.ant-collapse {
  background-color: rgb(217, 220, 224);
}
/deep/ .ant-collapse-content {
  border-top: 0px solid #d9d9d9 !important;
}
/deep/ .ant-collapse-item {
  background-color: rgb(217, 220, 224);
  .ant-collapse-content {
    background-color: rgb(217, 220, 224);
  }
}
/deep/ .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 7px 16px;
  padding-left: 40px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
  cursor: pointer;
  transition: all 0.3s;
}
.icon-color {
  color: rgb(161, 163, 168);
}
/deep/ .ant-upload.ant-upload-drag .ant-upload {
  padding: 0;
}
.open {
  position: fixed;
  top: 50%;
  height: 8%;
  background: #a1a3a8;
  border: 1px solid #bcc1c5;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  left: 40.7%;
  display: flex;
  z-index: 999;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #bcc1c5;
    color: black;
    cursor: pointer;
    opacity: 0.9 !important;
  }
}
.close {
  position: fixed;
  top: 50%;
  height: 8%;
  z-index: 999;
  background: #a1a3a8;
  border: 1px solid #bcc1c5;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  right: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #bcc1c5;
    color: black;
    cursor: pointer;
    opacity: 0.9 !important;
  }
}
.fun {
  font-size: 14px;
  position: relative;
  background-color: #f1f3f6;
  height: 5%;
  padding-left: 2px;
  padding-right: 15px;
  min-height: 40px;
  width: 100%;
  .fun-left,
  .fun-right {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  .fun-left {
    justify-content: center;
    padding-left: 11vw;
    .logo {
      position: absolute;
      left: 24px;
      top: 7px;
      img {
        height: 32px;
        min-width: 32px;
        position: relative;
        top: -3px;
        cursor: pointer;
      }
      span {
        margin-left: 10px;
        font-weight: bold;
        font-size: 20px;
      }
    }
  }
  .fun-right {
    padding-left: 1.4vw;
    padding-right: 0.7vw;
  }
}
.select_scale___2lG6l {
  margin-left: 15px;
  height: 26px;
  line-height: 26px;
  width: 70px;
  border: none;
  outline: none;
  &:focus-visible {
    border: none;
  }
}
.fun-input {
  display: inline-block;
  width: 34px;
  height: 26px;
  line-height: 1;
  background: #fff;
  text-align: center;
  border: none;
  margin-right: 2px;
}

/deep/ .ant-col-0 {
  display: block !important;
  width: 0 !important;
}
.ant-col {
  height: 95%;
  transition: 0.3s;
  transition-property: all;
  .show-div {
    position: relative;
    height: 100%;
    .show-head {
      width: 100%;
      height: 4%;
      min-height: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .name {
        width: 100%;
        background-color: #d9dce0;
        border-top: 1px solid #bcc1c5;
        border-bottom: 1px solid #bcc1c5;
        border-right: 1px solid #bcc1c5;
        display: flex;
        align-items: center;
        font-size: 14px;
        height: 100%;
        min-height: 30px;
        padding-left: 15px;
        .close-icon {
          position: absolute;
          right: 8px;
          font-size: 12px;
        }
      }
    }
    .show-body {
      height: 96%;
      box-sizing: border-box;
      .show-img {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border-bottom: 1px solid #bcc1c5;
        text-align: center;
        overflow-y: scroll;
        padding-right: 8px;
        /* overflow: overlay; */
        position: relative;
        .img-list {
          padding-top: 20px;
          z-index: 998;
          padding-bottom: 20px;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          .diff-div {
            position: absolute;
            border: 2px solid orange;
          }
          img {
            display: block;
            margin: 0 auto;
          }
          .div-list {
            position: absolute;
            top: 20px;
            left: 0;
            width: 100% !important;
            > div {
              position: relative;
            }
          }
        }
        > span {
          width: 100%;
          height: 100%;
          border: none;
          /deep/ .ant-upload.ant-upload-drag {
            background: rgba(0, 0, 0, 0) !important;
            border: none;
            &:hover {
              border: none;
            }
          }
          /deep/ .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
            color: rgba(0, 0, 0, 0.25) !important;
          }
        }
      }
    }
    .span-body {
      height: 96%;
      box-sizing: border-box;
      overflow-y: scroll;
      overflow: overlay;
      .show-img {
        height: 100%;
        background-color: #a1a3a8;
        padding-left: 100px;
        padding-top: 150px;
        border-bottom: 1px solid #bcc1c5;
        overflow-y: scroll;
      }
      /deep/ .ant-collapse .ant-collapse-item {
        border-bottom: none !important;
      }
      /deep/ .ant-collapse {
        border: 0px solid #d9d9d9 !important;
      }
    }
  }
  .span-div {
    height: 100%;
    background-color: #f1f3f6;
    .span-body {
      border-top: 1px solid #c7c8ca;
      width: 100%;
      height: 100%;
      background-color: #d9dce0;
    }
  }

  ::-webkit-scrollbar-button {
    height: 0;
  }
  ::-webkit-scrollbar {
    border-radius: 0;
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 0;
  }
  ::-webkit-scrollbar-track-piece {
    border-radius: 0;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: #c1c1c1;
  }
  .ant-slider {
    width: 7%;
    margin-top: 12px;
    margin-left: 15px;
  }
  /deep/ .ant-slider-rail {
    background-color: #e1e1e1 !important;
  }
  /deep/ .ant-slider-track {
    background-color: #a1a3a8 !important;
  }
  /deep/ .ant-slider-handle:focus {
    border-color: #a1a3a8;
    outline: none;
    box-shadow: unset !important;
  }
  /deep/ .ant-slider-handle:hover {
    border-color: #6d6e72;
    outline: none;
    box-shadow: 0 0 0 0px rgb(83 137 223 / 20%) !important;
  }
  /deep/ .ant-slider-handle {
    border: 2px solid #a1a3a8;
  }
  .btn {
    background-color: #fff;
    border: 1px solid #c1c1c1;
    height: 26px;
    min-width: 40px;
    padding-left: 0.8vw;
    padding-right: 0.8vw;
    border-radius: 3px;
    line-height: 1;
    &:hover {
      background-color: #f1f3f4;
      cursor: pointer;
    }
  }
  .btn-up {
    background-color: #fff;
    border: 1px solid black;
    height: 50px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 3px;
    font-weight: 700;
    &:hover {
      background-color: #f1f3f4;
      color: #9fce62;
      cursor: pointer;
    }
  }
}
</style>
